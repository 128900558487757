import { jsx as _jsx } from "react/jsx-runtime";
import { render } from 'react-dom';
import { sentryInit } from '@frontend-components/utils/sentry';
import { Provider } from 'react-redux';
import store from '@store/store';
import Routes from './src/routes';
import './i18n';
import '@frontend-components/css/global.scss';
import '@css/app.scss';
import { apiInit } from './apiMock';
const { APP_ENV, SENTRY_URL, IS_LOCAL_API_ENABLED } = process.env;
if (APP_ENV === 'production' && SENTRY_URL != null) {
    sentryInit(SENTRY_URL);
}
if (IS_LOCAL_API_ENABLED) {
    apiInit();
}
const PurchaseAgreementSpa = () => (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(Routes, {}) })));
render(_jsx(PurchaseAgreementSpa, {}), document.getElementById('purchase-agreement-spa-wrapper'));
