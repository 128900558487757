var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { repaApiUrl } from '@constants';
import { baseQueryWithAuth } from '../middlewares/baseQueryWithAuth';
export const REUSABLE_PARAGRAPHS_API_KEY = 'reusableParagraphsApi';
export const reusableParagaraphsApi = createApi({
    reducerPath: REUSABLE_PARAGRAPHS_API_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: `${repaApiUrl}/api` }),
    tagTypes: ['REUSABLE_PARAGRAPHS'],
    endpoints: (builder) => ({
        getReusableParagraphs: builder.query({
            query: () => ({
                url: '/paragraphs/reusable',
                method: 'GET'
            }),
            providesTags: ['REUSABLE_PARAGRAPHS']
        }),
        getReusableParagraph: builder.query({
            query: (id) => ({
                url: `/paragraphs/reusable/${id}`,
                method: 'GET'
            })
        }),
        postReusableParagraph: builder.mutation({
            query: (payload) => ({
                url: '/paragraphs/reusable',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['REUSABLE_PARAGRAPHS']
        }),
        putReusableParagraph: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/paragraphs/reusable/${id}`,
                method: 'PUT',
                body: payload
            }),
            onQueryStarted({ id, payload }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(reusableParagaraphsApi.util.updateQueryData('getReusableParagraphs', undefined, (paragraphs) => {
                        const targetParagraph = paragraphs.find((paragraph) => paragraph.id === id);
                        if (targetParagraph != null) {
                            Object.assign(targetParagraph, payload);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        deleteReusableParagraph: builder.mutation({
            query: (id) => ({
                url: `/paragraphs/reusable/${id}`,
                method: 'DELETE'
            }),
            onQueryStarted(id, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(reusableParagaraphsApi.util.updateQueryData('getReusableParagraphs', undefined, (paragraphs) => paragraphs.filter((paragraph) => paragraph.id !== id)));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        })
    })
});
export const { useGetReusableParagraphsQuery, usePostReusableParagraphMutation, useGetReusableParagraphQuery, usePutReusableParagraphMutation, useDeleteReusableParagraphMutation } = reusableParagaraphsApi;
