import { createApi } from '@reduxjs/toolkit/query/react';
import { repaApiUrl } from '@constants';
import { baseQueryWithAuth } from '../middlewares/baseQueryWithAuth';
export const IMPORTABLE_DOCUMENTS_API_KEY = 'importableDocumentsApi';
export const importableDocumentsApi = createApi({
    reducerPath: IMPORTABLE_DOCUMENTS_API_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: `${repaApiUrl}/api` }),
    endpoints: (builder) => ({
        postImportableDocument: builder.mutation({
            query: ({ key, formData }) => ({
                url: `${key}/importable-document`,
                method: 'POST',
                body: formData
            })
        })
    })
});
export const { usePostImportableDocumentMutation } = importableDocumentsApi;
