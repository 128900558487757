import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setErrorMessage } from '@store/slices/appSlice';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Alert from '@frontend-components/components/Alert';
const EditDocument = lazy(() => import('./EditDocument'));
const EditTemplate = lazy(() => import('./EditTemplate'));
const CreateDocument = lazy(() => import('./CreateDocument'));
const ReusableParagraphs = lazy(() => import('./ReusableParagraphs'));
const AppRoutes = () => {
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state) => ({ errorMessage: state.app.errorMessage }), shallowEqual);
    const alertElement = errorMessage != null ? _jsx(Alert, Object.assign({ onClose: () => dispatch(setErrorMessage(null)) }, { children: errorMessage })) : null;
    return (_jsxs(_Fragment, { children: [_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/document/:documentId", element: _jsx(Suspense, Object.assign({ fallback: "" }, { children: _jsx(EditDocument, {}) })) }), _jsx(Route, { path: "/template/:templateId", element: _jsx(Suspense, Object.assign({ fallback: "" }, { children: _jsx(EditTemplate, {}) })) }), _jsx(Route, { path: "/create-document", element: _jsx(Suspense, Object.assign({ fallback: "" }, { children: _jsx(CreateDocument, {}) })) }), _jsx(Route, { path: "/reusable-paragraphs", element: _jsx(Suspense, Object.assign({ fallback: "" }, { children: _jsx(ReusableParagraphs, {}) })) })] }) }), alertElement] }));
};
export default AppRoutes;
