var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { repaApiUrl } from '@constants';
import { baseQueryWithAuth } from '../middlewares/baseQueryWithAuth';
export const TABLE_MODULE_API_KEY = 'tableModuleApi';
export const tableModuleApi = createApi({
    reducerPath: TABLE_MODULE_API_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: `${repaApiUrl}/api` }),
    endpoints: (builder) => ({
        getParagraphTable: builder.query({
            query: ({ id, modelType }) => ({
                url: `/paragraphs/${id}/tables?model_type=${modelType}`,
                method: 'GET'
            })
        }),
        postParagraphTable: builder.mutation({
            query: ({ id, payload, modelType }) => ({
                url: `/paragraphs/${id}/tables`,
                method: 'POST',
                body: { items: payload, model_type: modelType }
            })
        }),
        putParagraphTable: builder.mutation({
            query: ({ id, payload, modelType }) => ({
                url: `/paragraphs/${id}/tables`,
                method: 'PUT',
                body: { items: payload, model_type: modelType }
            }),
            onQueryStarted({ id, payload, modelType }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(tableModuleApi.util.updateQueryData('getParagraphTable', { id, modelType }, () => payload));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        })
    })
});
export const { useGetParagraphTableQuery, useLazyGetParagraphTableQuery, usePostParagraphTableMutation, usePutParagraphTableMutation } = tableModuleApi;
