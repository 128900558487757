var _a, _b;
import { getServiceUrlFor } from '@frontend-components/utils/urls';
export const repaApiUrl = (_b = (_a = process.env) === null || _a === void 0 ? void 0 : _a.REPA_API_URL) !== null && _b !== void 0 ? _b : '';
export const administrationUrl = process.env.APP_ENV !== 'production' ? process.env.ADMINISTRATION_URL : getServiceUrlFor(window.location.href, 'application');
export var AvailableModules;
(function (AvailableModules) {
    AvailableModules["TOC"] = "toc";
    AvailableModules["PRIORITY"] = "priority";
    AvailableModules["PARAGRAPH"] = "paragraph";
    AvailableModules["TABLE"] = "table";
    AvailableModules["PRICE_TABLE"] = "price_table";
    AvailableModules["FAMILY_PRICE_TABLE"] = "family_price_table";
    AvailableModules["PRODUCTS_TABLE"] = "products_table";
})(AvailableModules || (AvailableModules = {}));
export var RepaModuleModelTypes;
(function (RepaModuleModelTypes) {
    RepaModuleModelTypes["DOCUMENT"] = "document";
    RepaModuleModelTypes["TEMPLATE"] = "template";
})(RepaModuleModelTypes || (RepaModuleModelTypes = {}));
export var ParagraphAttributes;
(function (ParagraphAttributes) {
    ParagraphAttributes["PAGE_BREAK"] = "page_break";
})(ParagraphAttributes || (ParagraphAttributes = {}));
export var ParagraphEditTypes;
(function (ParagraphEditTypes) {
    ParagraphEditTypes["VIEW"] = "view";
    ParagraphEditTypes["EDIT"] = "edit";
})(ParagraphEditTypes || (ParagraphEditTypes = {}));
export var LogicalOperators;
(function (LogicalOperators) {
    LogicalOperators["AND"] = "and";
    LogicalOperators["OR"] = "or";
})(LogicalOperators || (LogicalOperators = {}));
export var ComparisonOperators;
(function (ComparisonOperators) {
    ComparisonOperators["EQUALS"] = "=";
    ComparisonOperators["NOT_EQUALS"] = "!=";
})(ComparisonOperators || (ComparisonOperators = {}));
export var Operators;
(function (Operators) {
    Operators["LOGICAL_OPERATOR"] = "logical_operator";
    Operators["COMPARISON_OPERATOR"] = "comparison_operator";
})(Operators || (Operators = {}));
