import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isSideMenuOpen: false,
    isLazyLoadingEnabled: true,
    editParagraphId: null,
    errorMessage: null
};
export const APP_REDUCER_KEY = 'app';
const appSlice = createSlice({
    name: APP_REDUCER_KEY,
    initialState,
    reducers: {
        setSideMenuOpen(state, action) {
            state.isSideMenuOpen = action.payload;
        },
        setLazyLoadingEnabled(state, action) {
            state.isLazyLoadingEnabled = action.payload;
        },
        setEditParagraphId(state, action) {
            state.editParagraphId = action.payload;
        },
        setErrorMessage(state, action) {
            state.errorMessage = action.payload;
        }
    }
});
export const { setLazyLoadingEnabled, setSideMenuOpen, setEditParagraphId, setErrorMessage } = appSlice.actions;
export default appSlice.reducer;
