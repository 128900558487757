export function getUrlParam(urlString, param) {
    const url = new URL(urlString);
    return url.searchParams.get(param);
}
export function getServiceUrlFor(url, name) {
    let updatedUrl = url;
    // eslint-disable-next-line no-useless-escape
    const regExp = /http[s]?:\/\/?(www\.)?([^\.]+).*\.testaviva.dk(\/.*)*/;
    const result = regExp.exec(url);
    // For production and staging, the application microservice is aliased to "app"
    let microserviceName = name === 'application' ? 'app' : name;
    if (result != null && result[2] !== 'www') {
        if (result[3] !== '/') {
            updatedUrl = updatedUrl.replace(result[3], '');
        }
        else {
            updatedUrl = updatedUrl.slice(0, -1);
        }
        if (result[2] === 'staging') {
            return updatedUrl.replace(result[2], `${microserviceName}.${result[2]}`);
        }
        // For development environments
        if (url.indexOf('.development.testaviva.dk') !== -1) {
            // For these environments the application microservice is spelled out, and "app" should therefore translate to
            // "application" (the opposite is true for production and staging as described above).
            microserviceName = name === 'app' ? 'application' : name;
            // On QA environments, the admin microservice is on same URL as the application service.
            microserviceName = microserviceName === 'admin' ? 'application' : microserviceName;
            const qaIdentifier = /(qa-\w*)-.*/.exec(result[2]);
            if (qaIdentifier && qaIdentifier[1]) {
                return updatedUrl.replace(result[2], `${qaIdentifier[1]}-${microserviceName}`); // QA environments
            }
            return updatedUrl.replace(result[2], `shared-${microserviceName}`); // Shared environments
        }
        return updatedUrl.replace(result[2], microserviceName);
    }
    try {
        const urlParams = new URL(updatedUrl);
        if (urlParams.host === 'testaviva.dk' || urlParams.host === 'www.testaviva.dk') {
            return `${urlParams.protocol}//${name}.testaviva.dk`;
        }
    }
    catch (_a) {
        return undefined;
    }
    return undefined;
}
export function getWordpressApiUrl() {
    const currentUrl = new URL(window.location.href);
    if (currentUrl.host === 'testaviva.dk' || currentUrl.host === 'app.testaviva.dk') {
        return 'https://testaviva.dk';
    }
    if (currentUrl.host === 'staging.testaviva.dk') {
        return 'https://staging.testaviva.dk';
    }
    return 'https://shared-frontend.development.testaviva.dk';
}
