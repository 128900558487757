/* eslint-disable import/no-unresolved */
import { createServer, Model, belongsTo } from 'miragejs';
import { documentData, metadata, variables, templates, importableDocumentsData, importableDocuments, priorities, reusableParagraphs, tableModuleData } from './data';
import { repaApiUrl, administrationUrl } from '@constants';
const variablesAray = [...variables];
const templatesData = [...templates];
const prioritiesArray = [...priorities];
const reusableParagraphsArray = [...reusableParagraphs];
let tableModuleDataArray = [...tableModuleData];
export const apiInit = () => {
    createServer({
        models: {
            documents: Model,
            templates: Model,
            paragraph: Model.extend({
                documents: belongsTo(),
                templates: belongsTo()
            }),
            metadata: Model.extend({
                documents: belongsTo(),
                templates: belongsTo()
            }),
            variables: Model.extend({
                documets: belongsTo(),
                templates: belongsTo()
            })
        },
        seeds(server) {
            server.db.loadData({
                documents: [documentData],
                templates: [templatesData],
                metadata: [metadata],
                variables: [variables]
            });
        },
        routes() {
            this.urlPrefix = repaApiUrl;
            this.get('/api/documents/:id', (schema, request) => schema.documents.findBy({ id: request.params.id }).attrs);
            this.post('/api/documents', () => ({ id: 10 }));
            this.delete('/api/documents/:documentId/paragraphs/:id', (schema, request) => {
                const { id, documentId } = request.params;
                const document = schema.db.documents.find(documentId);
                document.paragraphs = document.paragraphs.filter((paragraph) => paragraph.id !== Number(id));
            });
            this.post('/api/documents/:id/paragraphs', (schema, request) => {
                const document = schema.documents.findBy({ id: request.params.id });
                const id = Math.floor(Math.random() * 100) + 10000000;
                const updatedParagraphs = [];
                document.paragraphs.forEach((paragraph) => {
                    const data = JSON.parse(request.requestBody);
                    if (paragraph.order > data.order) {
                        paragraph.order += 1;
                        updatedParagraphs.push(paragraph);
                    }
                    else {
                        updatedParagraphs.push(paragraph);
                    }
                });
                document.paragraphs = [...updatedParagraphs, Object.assign({ id }, JSON.parse(request.requestBody))];
                document.save();
                return { id };
            });
            this.put('/api/documents/:documentId/paragraphs/:id', (_, request) => console.warn(request));
            this.get('/api/documents/:id/metadata', () => metadata);
            this.get('/api/documents/:id/variables', () => variablesAray);
            this.post('/api/documents/:id/variables', (_, request) => {
                const id = Math.floor(Math.random() * 100);
                variablesAray.push(Object.assign({ id }, JSON.parse(request.requestBody)));
            });
            this.put('/api/documents/:documentId/variables/:id', (_, request) => console.warn(request));
            this.delete('/api/documents/:documentId/variables/:id', (_, request) => console.warn(request));
            this.get('/api/documents/:id/modules/toc', () => '<b>TOC</b>');
            this.post('/api/documents/:id/metadata', (_, request) => {
                const id = Math.floor(Math.random() * 100);
                metadata.push(Object.assign({ id }, JSON.parse(request.requestBody)));
            });
            this.delete('/api/documents/:documentId/metadata/:id', (_, request) => console.warn(request));
            this.get('/api/documents/:id/download', (_, request) => console.warn(request));
            this.get('/api/templates', () => templatesData);
            this.get('/api/templates/:templateId', (_, request) => templates[Number(request.params.templateId) - 1]);
            this.put('/api/templates/:templateId/paragraphs/:id', (_, request) => console.warn(request));
            this.delete('/api/templates/:templateId/paragraphs/:id', (_, request) => console.warn(request));
            // eslint-disable-next-line consistent-return
            this.post('/api/templates/:templateId/paragraphs', (_, request) => {
                const id = Math.floor(Math.random() * 100) + 10000000;
                const updatedParagraphs = [];
                const template = templatesData.find((templateItem) => templateItem.id === Number(request.params.templateId));
                if (template != null) {
                    template.paragraphs.forEach((paragraph) => {
                        const data = JSON.parse(request.requestBody);
                        if (paragraph.order > data.order) {
                            paragraph.order += 1;
                            updatedParagraphs.push(paragraph);
                        }
                        else {
                            updatedParagraphs.push(paragraph);
                        }
                    });
                    template.paragraphs = [...updatedParagraphs, Object.assign({ id }, JSON.parse(request.requestBody))];
                    return { id };
                }
            });
            this.put('/api/templates/:templateId/questions/:id', (_, request) => {
                const template = templatesData.find((templateItem) => templateItem.id === Number(request.params.templateId));
                if (template != null) {
                    const question = template.questions.find((questionItem) => questionItem.id === Number(request.params.id));
                    if (question != null) {
                        Object.assign(question, JSON.parse(request.requestBody));
                    }
                }
            });
            this.post('/api/templates/:templateId/questions', (_, request) => {
                var _a;
                const id = Math.floor(Math.random() * 100) + 1000;
                const template = templatesData.find((templateItem) => templateItem.id === Number(request.params.templateId));
                if (template != null) {
                    const questions = (_a = template === null || template === void 0 ? void 0 : template.questions) !== null && _a !== void 0 ? _a : [];
                    questions.push({
                        id, question: '', answers: [''], conditions: [], datascraped: false
                    });
                    Object.assign(template, { questions });
                }
            });
            this.delete('/api/templates/:templateId/questions/:id', (_, request) => console.warn(request));
            this.get(`${administrationUrl}/admin/api/backenduser/token`, () => 'someToken');
            this.get('/api/templates/:templateId/importable-documents', () => importableDocumentsData);
            this.put('/api/templates/:id/importable-documents/:importableDocumentKey', (_, request) => console.warn(request));
            this.post('/api/:key/importable-document', (_, request) => importableDocuments[request.params.key]);
            this.get('/api/templates/:id/variables', () => variablesAray);
            this.get('/api/templates/:id/modules/toc', () => '<b>TOC</b>');
            this.get('/api/templates/:id/metadata', () => metadata);
            // Priorities api
            this.get('/api/documents/:documentId/priorities', () => priorities);
            this.put('/api/documents/:documentId/priorities/:id', (_, request) => console.warn(request));
            this.post('/api/documents/:documentId/priorities', (_, request) => {
                const id = Math.floor(Math.random() * 100);
                prioritiesArray.push(Object.assign({ id }, JSON.parse(request.requestBody)));
            });
            this.delete('/api/documents/:documentId/priorities/:id', (_, request) => console.warn(request));
            // Reusable paragraphs api
            this.get('/api/paragraphs/reusable', () => reusableParagraphsArray);
            this.get('/api/paragraphs/reusable/:id', (_, request) => reusableParagraphsArray[request.params.id]);
            this.put('/api/paragraphs/reusable/:id', (_, request) => console.warn(request));
            this.post('/api/paragraphs/reusable', (_, request) => {
                const id = Math.floor(Math.random() * 100);
                reusableParagraphsArray.push(Object.assign({ id }, JSON.parse(request.requestBody)));
            });
            this.delete('/api/paragraphs/reusable/:id', (_, request) => console.warn(request));
            // Table module api
            this.get('/api/paragraphs/:id/tables', () => tableModuleDataArray);
            this.post('/api/paragraphs/:id/tables', (_, request) => {
                const newCells = JSON.parse(request.requestBody).items.map((cell) => (Object.assign(Object.assign({}, cell), { id: (Math.floor(Math.random() * 1000)) + (Math.floor(Math.random() * 100)) })));
                tableModuleDataArray = [...tableModuleDataArray, ...newCells];
            });
            this.put('/api/paragraphs/:id/tables', (_, request) => {
                tableModuleDataArray = [...JSON.parse(request.requestBody).items];
            });
        }
    });
};
