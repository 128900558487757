var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { repaApiUrl } from '@constants';
import { baseQueryWithAuth } from '../middlewares/baseQueryWithAuth';
export const TEMPLATES_API_KEY = 'templatesApi';
export const templatesApi = createApi({
    reducerPath: TEMPLATES_API_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: `${repaApiUrl}/api` }),
    tagTypes: ['TEMPLATE', 'TEMPLATE_VARIABLES'],
    endpoints: (builder) => ({
        getTemplates: builder.query({
            query: () => ({
                url: '/templates',
                method: 'GET'
            })
        }),
        getTemplate: builder.query({
            query: (id) => ({
                url: `/templates/${id}`,
                method: 'GET'
            }),
            providesTags: ['TEMPLATE']
        }),
        putTemplate: builder.mutation({
            query: ({ id, payload }) => ({
                url: `/templates/${id}`,
                method: 'PUT',
                body: payload
            }),
            onQueryStarted({ id, payload }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplate', id, (templateData) => {
                        Object.assign(templateData, payload);
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        postTemplateParagraph: builder.mutation({
            query: ({ templateId, payload }) => ({
                url: `/templates/${templateId}/paragraphs`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['TEMPLATE']
        }),
        putTemplateParagraph: builder.mutation({
            query: ({ templateId, id, payload }) => ({
                url: `/templates/${templateId}/paragraphs/${id}`,
                method: 'PUT',
                body: payload
            }),
            onQueryStarted({ templateId, id, payload }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplate', templateId, (templateData) => {
                        const targetParagraph = templateData.paragraphs.find((paragraph) => paragraph.id === id);
                        if (targetParagraph != null) {
                            Object.assign(targetParagraph, payload);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        deleteTemplateParagraph: builder.mutation({
            query: ({ templateId, id }) => ({
                url: `/templates/${templateId}/paragraphs/${id}`,
                method: 'DELETE'
            }),
            onQueryStarted({ templateId, id }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplate', templateId, (templateData) => {
                        templateData.paragraphs = templateData.paragraphs.filter((paragraph) => paragraph.id !== id);
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        getTemplateVariables: builder.query({
            query: (templateId) => ({
                url: `/templates/${templateId}/variables`,
                method: 'GET'
            }),
            providesTags: ['TEMPLATE_VARIABLES']
        }),
        postTemplateVariable: builder.mutation({
            query: ({ templateId, payload }) => ({
                url: `/templates/${templateId}/variables`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['TEMPLATE_VARIABLES']
        }),
        deleteTemplateVariable: builder.mutation({
            query: ({ templateId, variableId }) => ({
                url: `/templates/${templateId}/variables/${variableId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['TEMPLATE_VARIABLES'],
            onQueryStarted({ templateId, variableId }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplateVariables', templateId, (templateVariablesData) => templateVariablesData.filter((variable) => variable.id !== variableId)));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        postTemplateQuestion: builder.mutation({
            query: (templateId) => ({
                url: `/templates/${templateId}/questions`,
                method: 'POST'
            }),
            invalidatesTags: ['TEMPLATE']
        }),
        putTemplateQuestion: builder.mutation({
            query: ({ templateId, questionId, payload }) => ({
                url: `/templates/${templateId}/questions/${questionId}`,
                method: 'PUT',
                body: payload
            }),
            onQueryStarted({ templateId, questionId, payload }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplate', templateId, (templateData) => {
                        const affectedQuestion = templateData.questions.find((question) => question.id === questionId);
                        if (affectedQuestion != null) {
                            Object.assign(affectedQuestion, payload);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        deleteTemplateQuestion: builder.mutation({
            query: ({ templateId, questionId }) => ({
                url: `/templates/${templateId}/questions/${questionId}`,
                method: 'DELETE'
            }),
            onQueryStarted({ templateId, questionId }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplate', templateId, (templateData) => {
                        templateData.questions = templateData.questions.filter((question) => question.id !== questionId);
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        getTemplateImportableDocuments: builder.query({
            query: (templateId) => ({
                url: `/templates/${templateId}/importable-documents`,
                method: 'GET'
            })
        }),
        putTemplateImportableDocument: builder.mutation({
            query: ({ templateId, importableDocumentKey, isActivated }) => ({
                url: `/templates/${templateId}/importable-documents/${importableDocumentKey}`,
                method: 'PUT',
                body: { is_activated: isActivated }
            }),
            invalidatesTags: ['TEMPLATE', 'TEMPLATE_VARIABLES'],
            onQueryStarted({ templateId, importableDocumentKey, isActivated }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(templatesApi.util.updateQueryData('getTemplateImportableDocuments', templateId, (importableDocuments) => {
                        const affectedDocument = importableDocuments.find((document) => document.key === importableDocumentKey);
                        if (affectedDocument != null) {
                            affectedDocument.is_activated = isActivated;
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        getTemplateTOC: builder.query({ query: (templateId) => ({ url: `/templates/${templateId}/modules/toc` }) }),
        getTemplateMetadata: builder.query({
            query: (templateId) => ({
                url: `/templates/${templateId}/metadata`,
                method: 'GET'
            })
        })
    })
});
export const { useGetTemplatesQuery, useGetTemplateQuery, useLazyGetTemplateQuery, usePutTemplateMutation, usePostTemplateParagraphMutation, usePutTemplateParagraphMutation, useDeleteTemplateParagraphMutation, useGetTemplateVariablesQuery, useLazyGetTemplateVariablesQuery, usePostTemplateVariableMutation, usePostTemplateQuestionMutation, usePutTemplateQuestionMutation, useDeleteTemplateQuestionMutation, useDeleteTemplateVariableMutation, useGetTemplateImportableDocumentsQuery, usePutTemplateImportableDocumentMutation, useGetTemplateTOCQuery, useGetTemplateMetadataQuery } = templatesApi;
