import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { documentsApi } from './services/documents';
import { templatesApi } from './services/templates';
import { importableDocumentsApi } from './services/importableDocuments';
import { reusableParagaraphsApi } from './services/reusableParagraphs';
import { tableModuleApi } from './services/tableModule';
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        documentsApi.middleware,
        templatesApi.middleware,
        importableDocumentsApi.middleware,
        reusableParagaraphsApi.middleware,
        tableModuleApi.middleware
    ])
});
export default store;
