var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { store } from '@store/store';
import { setErrorMessage } from '@store/slices/appSlice';
import i18n from 'i18next';
import { administrationUrl } from '@constants';
import { authHeaderName } from '@frontend-components/constants';
export const baseQueryWithAuth = ({ baseUrl }) => (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const addAuthHeader = (authToken) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const result = yield fetchBaseQuery({
            baseUrl,
            prepareHeaders: (headers) => {
                if (authToken != null && authToken.length > 0) {
                    headers.set(authHeaderName, authToken);
                }
                return headers;
            }
        })(args, api, extraOptions);
        if (((_b = result.error) === null || _b === void 0 ? void 0 : _b.status) === 401) {
            store.dispatch(setErrorMessage('Unauthenticated'));
        }
        else if (result.error) {
            store.dispatch(setErrorMessage(i18n.t('global.errors.internalGenericError')));
        }
        return result;
    });
    let tokenHeaderValue = null;
    if (process.env.TOKEN != null && process.env.TOKEN.length > 0) {
        tokenHeaderValue = process.env.TOKEN;
        window.sessionStorage.setItem(authHeaderName, process.env.TOKEN);
    }
    else {
        tokenHeaderValue = (_a = window === null || window === void 0 ? void 0 : window.sessionStorage) === null || _a === void 0 ? void 0 : _a.getItem(authHeaderName);
    }
    if (tokenHeaderValue == null) {
        try {
            const response = yield fetch(`${administrationUrl}/admin/api/backenduser/token`, { credentials: 'include' });
            if (response.ok && !response.redirected) {
                const responseToken = yield response.text();
                window.sessionStorage.setItem(authHeaderName, responseToken);
                tokenHeaderValue = responseToken;
            }
        }
        finally {
            // eslint-disable-next-line no-unsafe-finally
            return addAuthHeader(tokenHeaderValue);
        }
    }
    return addAuthHeader(tokenHeaderValue);
});
