export var Products;
(function (Products) {
    Products["will"] = "will";
    Products["prenup"] = "prenup";
    Products["future_pow"] = "future_pow";
    Products["coop"] = "coop";
    Products["favored"] = "favored";
    Products["undivided_estate_agreement"] = "undivided_estate_agreement";
    Products["pow"] = "pow";
    Products["buyer_advice"] = "buyer_advice";
    Products["will_contents"] = "will_contents";
    Products["promissory_note"] = "promissory_note";
    Products["deed_of_gift"] = "deed_of_gift";
    Products["existing_document"] = "existing_documen";
    Products["guidance"] = "guidance";
    Products["will_child"] = "will_child";
    Products["custom"] = "custom";
    Products["property_division_agreement"] = "property_division_agreement";
    Products["deed_property"] = "deed_property";
    Products["inheritance_advance"] = "inheritance_advance";
    Products["inheritance_waiver"] = "inheritance_waiver";
    Products["child_support_agreement"] = "child_support_agreement";
    Products["togetherness_agreement"] = "togetherness_agreement";
    Products["spousal_support_agreement"] = "spousal_support_agreement";
    Products["prenuptial_exit"] = "prenuptial_exit";
    Products["deed_divorce"] = "deed_divorce";
    Products["prenuptial_pension"] = "prenuptial_pension";
    Products["advisor_agreement"] = "advisor_agreement";
    Products["application_to_agriculture_agency"] = "application_to_agriculture_agency";
    Products["application_to_civil_agency"] = "application_to_civil_agency";
    Products["debitor_shift"] = "debitor_shift";
    Products["family_trade"] = "family_trade";
    Products["price_negotiation"] = "price_negotiation";
    Products["private_trade"] = "private_trade";
    Products["transfer_agreement"] = "transfer_agreement";
    Products["probate_certificate"] = "probate_certificate";
})(Products || (Products = {}));
export const digitalProducts = [
    Products.coop,
    Products.future_pow,
    Products.prenup,
    Products.undivided_estate_agreement,
    Products.will
];
