const paragraphs = [
    {
        id: 10,
        content: '',
        order: 1,
        type: 'paragraph',
        attributes: []
    },
    {
        id: 9,
        content: '<table class="table-module"><tbody><tr class="table-module-row"><td style="" class="table-module-row-cell"><p><b>something</b> here</p></td><td style="" class="table-module-row-cell"><p><b>something</b> here 2</p></td><td style="" class="table-module-row-cell"><p><b>something</b> here 3</p></td><td style="" class="table-module-row-cell"></td></tr><tr class="table-module-row"><td style="" class="table-module-row-cell"><p>row 2 column 1</p></td><td style="" class="table-module-row-cell"><p>row 2 column 2</p></td><td style="" class="table-module-row-cell"><p>row 2 column 3</p></td><td style="" class="table-module-row-cell"></td></tr></tbody></table>',
        order: 2,
        type: 'table',
        attributes: []
    },
    {
        id: 1,
        content: `
      <h2>Title 1</h2>
      <p>Text</p>
      <h3>Title 3.1</h3>
      <h3>Title 3.2</h3>
      <p>Text Text</p>
      <p>Text Text</p>
      <h4>Title 4.1</h4>
      <h4>Title 4.2</h4>
      <p>Text Text</p>
      <p>Text Text</p>
      <p>$test$ $test$</p>
    `,
        order: 2,
        type: 'paragraph'
    },
    {
        id: 2,
        content: `
      <p>Text Text</p>
      <p>Text Text</p>
    `,
        order: 3,
        type: 'paragraph'
    },
    {
        id: 3,
        content: `
      <h2>Title 1</h2>
      <p>Text</p>
      <h3>Title 3.1</h3>
      <h3>Title 3.2</h3>
      <p>Text Text</p>
      <p>Text Text</p>
      <h4>Title 4.1</h4>
      <h4>Title 4.2</h4>
      <p>Text Text</p>
      <p>Text Text</p>
      <p>$test$ $test$</p>
    `,
        order: 4,
        type: 'paragraph'
    },
    {
        id: 4,
        content: `
      <p>Text</p>
      <h3>Title 3.1</h3>
      <h3>Title 3.2</h3>
      <p>Text Text</p>
      <p>Text Text</p>
      <h4>Title 4.1</h4>
      <h4>Title 4.2</h4>
      <p>Text Text</p>
      <p>Text Text</p>
    `,
        order: 5,
        type: 'paragraph'
    }
];
export const documentData = {
    id: 10,
    paragraphs,
    title: 'Some Title',
    settings: { update_document: true },
    questions: [
        {
            id: 1,
            question: 'Bolig',
            datascraped: 0,
            answers: ['Apartment', 'Summerhouse', 'Villa']
        },
        {
            id: 2,
            question: 'Is furniture?',
            answers: ['Yes', 'No'],
            datascraped: 0,
            conditions: [
                {
                    question: 1,
                    value: ['Apartment', 'Villa']
                }
            ]
        },
        {
            id: 3,
            question: 'Is 1 === 2?',
            answers: ['Yes', 'No'],
            datascraped: 0,
            conditions: [
                {
                    question: 1,
                    value: ['Villa']
                },
                {
                    question: 2,
                    value: ['Yes']
                }
            ]
        },
        {
            id: 4,
            question: 'Is 1 === 3?',
            answers: ['Yes', 'No'],
            datascraped: 1,
            conditions: []
        }
    ],
    warnings: [
        {
            title: 'Warning title',
            text: 'Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text'
        },
        {
            title: 'Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text Warning text',
            text: 'Warning title'
        }
    ]
};
export const metadata = [
    {
        id: 1,
        title: 'Input title',
        value: 'Input value',
        type: 'input',
        is_editable: true,
        category: 'buyer_info',
        options: null,
        identifier: '1_buyer_name',
        can_be_multiple: true,
        is_deletable: false
    },
    {
        id: 2,
        title: 'DatePicker title',
        value: '2025-02-01',
        type: 'date',
        is_editable: false,
        options: null,
        category: 'property_info',
        can_be_multiple: false,
        identifier: 'some_identifier',
        is_deletable: false
    },
    {
        id: 3,
        title: 'Select title',
        value: 'weird_value_that_needs_to_be_translated',
        type: 'input',
        is_editable: false,
        options: null,
        category: 'property_info',
        can_be_multiple: false,
        identifier: 'some_identifier',
        is_deletable: false
    },
    {
        id: 4,
        title: 'Checkbox title',
        value: 'true',
        type: 'input',
        options: null,
        is_editable: true,
        category: 'buyer_info',
        identifier: '1_buyer_phone',
        can_be_multiple: true,
        is_deletable: false
    },
    {
        id: 5,
        title: 'Title 2',
        value: '',
        options: null,
        type: 'date',
        is_editable: true,
        category: null,
        can_be_multiple: false,
        identifier: 'some_identifier',
        is_deletable: false
    },
    {
        id: 6,
        title: 'Select title',
        value: '',
        options: { primary_receiver: 'Primary Receiver', subsidiary_receiver: 'Subsidiary Receiver' },
        type: 'select',
        is_editable: true,
        category: null,
        can_be_multiple: false,
        identifier: 'some_identifier',
        is_deletable: false
    }
];
export const variables = [
    {
        id: 1,
        name: '$real_estate_agent$',
        value: 'Danbolig København',
        description: 'Danbolig København Danbolig København Danbolig København Danbolig København',
        is_deletable: true
    },
    {
        id: 2,
        name: '$name$',
        value: 'Some dude',
        description: 'Danbolig København Danbolig København Danbolig København Danbolig København Danbolig København Danbolig København Danbolig København Danbolig København',
        is_deletable: true
    },
    {
        id: 3,
        name: '$email$',
        value: 'Some dude email',
        description: 'Danbolig København Danbolig København Danbolig København Danbolig København',
        is_deletable: true
    },
    {
        id: 4,
        name: '$fullname$',
        value: 'Some dude fullname',
        description: '',
        is_deletable: false
    },
    {
        id: 5,
        name: '$test$',
        value: 'test variable',
        description: null,
        is_deletable: true
    }
];
export const templates = [
    {
        id: 1,
        type: 'will',
        title: 'Will template',
        paragraphs,
        settings: { show_questions: true, show_paragraphs: true },
        questions: [
            {
                id: 1,
                question: 'order 1',
                answers: ['Apartment', 'Summerhouse', 'Villa']
            },
            {
                id: 2,
                question: 'order 2, 1 parent',
                answers: ['Yes', 'No'],
                datascraped: 0,
                conditions: [
                    {
                        question: 1,
                        value: ['Apartment', 'Villa']
                    }
                ]
            },
            {
                id: 3,
                question: 'order 3, 2 parents',
                answers: ['Yes', 'No'],
                datascraped: 0,
                conditions: [
                    {
                        question: 1,
                        value: ['Villa']
                    },
                    {
                        question: 2,
                        value: ['Yes']
                    }
                ]
            },
            {
                id: 4,
                question: 'order 5',
                datascraped: 0,
                answers: ['Yes', 'No']
            },
            {
                id: 5,
                question: 'order 4, 1 parent',
                answers: ['Yes', 'No'],
                datascraped: 0,
                conditions: [
                    {
                        question: 4,
                        value: ['Yes']
                    }
                ]
            },
            {
                id: 6,
                question: 'Scraped question',
                answers: ['Yes', 'No'],
                datascraped: 1,
                conditions: []
            }
        ]
    },
    {
        id: 2,
        type: 'future_pow',
        title: 'Future pow template',
        paragraphs: [],
        settings: { show_question: false }
    },
    {
        id: 3,
        type: 'pow',
        title: 'Pow template'
    }
];
export const importableDocumentsData = [
    {
        key: 'property_data_report',
        is_activated: true
    },
    {
        key: 'something_1',
        is_activated: true
    },
    {
        key: 'something_2',
        is_activated: false
    }
];
export const importableDocuments = {
    property_data_report: [
        {
            key: 'key_1',
            value: 'value_1'
        },
        {
            key: 'key_2',
            value: 'value_2'
        },
        {
            key: 'key_3',
            value: 'value_3'
        }
    ],
    something_1: [
        {
            key: 'key_11',
            value: 'value_11'
        },
        {
            key: 'key_22',
            value: 'value_22'
        }
    ]
};
export const priorities = [
    {
        id: 1,
        paragraph_id: 2,
        priority: 3,
        text: 'Paragraph 1 priority text'
    },
    {
        id: 2,
        paragraph_id: 1,
        priority: 1,
        text: 'Paragraph 2 priority text'
    },
    {
        id: 3,
        paragraph_id: 3,
        priority: 2,
        text: 'Paragraph 3 priority text'
    },
    {
        id: 4,
        paragraph_id: 4,
        priority: 1,
        text: 'Paragraph 4 priority text'
    }
];
export const reusableParagraphs = [
    {
        id: 1,
        name: 'Paragraph 1',
        content: `
      <h2>Title 1</h2>
      <p>Text</p>
      <h3>Title 3.1</h3>
      <h3>Title 3.2</h3>
      <p>Text Text</p>
      <p>Text Text</p>
      <h4>Title 4.1</h4>
      <h4>Title 4.2</h4>
      <p>Text Text</p>
      <p>Text Text</p>
    `,
        templates: [
            {
                id: 1,
                title: 'Template 1',
                type: 'Template type 1'
            },
            {
                id: 2,
                title: 'Template 2',
                type: 'Template type 2'
            },
            {
                id: 3,
                title: 'Template 3',
                type: 'Template type 3'
            }
        ]
    },
    {
        id: 2,
        name: 'Paragraph 2',
        content: `
      <h2>Title 1</h2>
      <p>Text</p>
      <h3>Title 3.1</h3>
      <h3>Title 3.2</h3>
      <p>Text Text</p>
      <p>Text Text</p>
      <h4>Title 4.1</h4>
      <h4>Title 4.2</h4>
      <p>Text Text</p>
      <p>Text Text</p>
    `,
        templates: [
            {
                id: 21,
                title: 'Template 21',
                type: 'Template type 21'
            },
            {
                id: 22,
                title: 'Template 22',
                type: 'Template type 22'
            },
            {
                id: 23,
                title: 'Template 23',
                type: 'Template type 23'
            }
        ]
    },
    {
        id: 3,
        name: 'Paragraph 3',
        content: 'GG'
    }
];
export const tableModuleData = [
    {
        paragraph_id: 9,
        id: 1,
        row: 1,
        column: 1,
        content: '<p><b>something</b> here</p>',
        styles: 'background-color:#c03030;width:330px;height:50px;padding:10px;box-sizing:border-box;'
    },
    {
        paragraph_id: 9,
        id: 6,
        row: 2,
        column: 2,
        content: '<p>row 2 column 2</p>',
        styles: ''
    },
    {
        paragraph_id: 9,
        id: 2,
        row: 1,
        column: 2,
        content: '<p><b>something</b> here 2</p>',
        styles: ''
    },
    {
        paragraph_id: 9,
        id: 3,
        row: 1,
        column: 3,
        content: '<p><b>something</b> here 3</p>',
        styles: ''
    },
    {
        paragraph_id: 9,
        id: 4,
        row: 2,
        column: 3,
        content: '<p>row 2 column 3</p>',
        styles: ''
    },
    {
        paragraph_id: 9,
        id: 5,
        row: 2,
        column: 1,
        content: '<p>row 2 column 1</p>',
        styles: ''
    }
];
