var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { repaApiUrl } from '@constants';
import { baseQueryWithAuth } from '../middlewares/baseQueryWithAuth';
export const DOCUMENTS_API_REDUCER_KEY = 'documentsApi';
export const documentsApi = createApi({
    reducerPath: DOCUMENTS_API_REDUCER_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: `${repaApiUrl}/api` }),
    tagTypes: ['DOCUMENT', 'VARIABLES', 'PRIORITIES'],
    endpoints: (builder) => ({
        getDocument: builder.query({
            query: (id) => ({
                url: `/documents/${id}`,
                method: 'GET'
            }),
            providesTags: ['DOCUMENT']
        }),
        postDocument: builder.mutation({
            query: (payload) => ({
                url: '/documents',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['DOCUMENT']
        }),
        putDocumentPagragraph: builder.mutation({
            query: ({ documentId, payload, id }) => ({
                url: `/documents/${documentId}/paragraphs/${id}`,
                method: 'PUT',
                body: payload
            }),
            onQueryStarted({ documentId, id, payload }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocument', documentId, (documentData) => {
                        const targetParagraph = documentData.paragraphs.find((paragraph) => paragraph.id === id);
                        if (targetParagraph != null) {
                            Object.assign(targetParagraph, payload);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        postDocumentParagraph: builder.mutation({
            query: ({ documentId, payload }) => ({
                url: `/documents/${documentId}/paragraphs`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['DOCUMENT']
        }),
        deleteDocumentParagraph: builder.mutation({
            query: ({ id, documentId }) => ({
                url: `/documents/${documentId}/paragraphs/${id}`,
                method: 'DELETE'
            }),
            onQueryStarted({ id, documentId }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocument', documentId, (documentData) => {
                        documentData.paragraphs = documentData.paragraphs.filter((paragraph) => paragraph.id !== id);
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        getDocumentMetadata: builder.query({
            query: (id) => ({
                url: `/documents/${id}/metadata`,
                method: 'GET'
            })
        }),
        putDocumentMetadata: builder.mutation({
            query: ({ documentId, id, value }) => ({
                url: `/documents/${documentId}/metadata/${id}`,
                method: 'PUT',
                body: { value }
            }),
            onQueryStarted({ documentId, id, value }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocumentMetadata', documentId, (metadata) => {
                        const affectedItem = metadata.find((item) => item.id === id);
                        if (affectedItem != null) {
                            affectedItem.value = value;
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        postDocumentMetadata: builder.mutation({
            query: ({ documentId, metadata }) => ({
                url: `/documents/${documentId}/metadata`,
                method: 'POST',
                body: metadata
            })
        }),
        deleteDocumentMetadata: builder.mutation({
            query: ({ documentId, id }) => ({
                url: `/documents/${documentId}/metadata/${id}`,
                method: 'DELETE'
            }),
            onQueryStarted({ documentId, id }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocumentMetadata', documentId, (metadata) => metadata.filter((metadataItem) => metadataItem.id !== id)));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        getDocumentVariables: builder.query({
            query: (documentId) => ({
                url: `/documents/${documentId}/variables`,
                method: 'GET'
            }),
            providesTags: ['VARIABLES']
        }),
        putDocumentVariable: builder.mutation({
            query: (_a) => {
                var { documentId, id } = _a, body = __rest(_a, ["documentId", "id"]);
                return ({
                    url: `/documents/${documentId}/variables/${id}`,
                    method: 'PUT',
                    body
                });
            },
            onQueryStarted(_a, _b) {
                var { id, documentId } = _a, body = __rest(_a, ["id", "documentId"]);
                var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled;
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocumentVariables', documentId, (variables) => {
                        const affectedVariable = variables.find((variable) => variable.id === id);
                        if (affectedVariable) {
                            Object.assign(affectedVariable, body);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_c) {
                        patchResult.undo();
                    }
                });
            }
        }),
        deleteDocumentVariable: builder.mutation({
            query: ({ documentId, id }) => ({
                url: `/documents/${documentId}/variables/${id}`,
                method: 'DELETE'
            }),
            onQueryStarted({ id, documentId }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocumentVariables', documentId, (variables) => variables.filter((variable) => variable.id !== id)));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        postDocumentVariable: builder.mutation({
            query: ({ documentId, payload }) => ({
                url: `/documents/${documentId}/variables`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['VARIABLES']
        }),
        getDocumentPriorities: builder.query({
            query: (documentId) => ({
                url: `/documents/${documentId}/priorities`,
                method: 'GET'
            }),
            providesTags: ['PRIORITIES']
        }),
        postDocumentPriorities: builder.mutation({
            query: (_a) => {
                var { documentId } = _a, priorityPayload = __rest(_a, ["documentId"]);
                return ({
                    url: `/documents/${documentId}/priorities`,
                    method: 'POST',
                    body: priorityPayload
                });
            },
            invalidatesTags: ['PRIORITIES']
        }),
        deleteDocumentPriorities: builder.mutation({
            query: ({ documentId, id }) => ({
                url: `/documents/${documentId}/priorities/${id}`,
                method: 'DELETE'
            }),
            onQueryStarted({ documentId, id }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocumentPriorities', documentId, (priorities) => priorities.filter((priority) => priority.id !== id)));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                        patchResult.undo();
                    }
                });
            }
        }),
        putDocumentPriorities: builder.mutation({
            query: (_a) => {
                var { documentId, id } = _a, priorityPayload = __rest(_a, ["documentId", "id"]);
                return ({
                    url: `/documents/${documentId}/priorities/${id}`,
                    method: 'PUT',
                    body: priorityPayload
                });
            },
            onQueryStarted(_a, _b) {
                var { documentId, id } = _a, priorityPayload = __rest(_a, ["documentId", "id"]);
                var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled;
                return __awaiter(this, void 0, void 0, function* () {
                    const patchResult = dispatch(documentsApi.util.updateQueryData('getDocumentPriorities', documentId, (priorities) => {
                        const targetPriority = priorities.find((priority) => priority.id === id);
                        if (targetPriority != null) {
                            Object.assign(targetPriority, priorityPayload);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (_c) {
                        patchResult.undo();
                    }
                });
            }
        }),
        getDocumentReusableParagraphs: builder.query({
            query: (id) => ({
                url: `/documents/${id}/reusable-paragraphs`,
                method: 'GET'
            })
        }),
        getDocumentTOC: builder.query({
            query: (documentId) => ({
                url: `/documents/${documentId}/modules/toc`,
                method: 'GET'
            })
        }),
        getDocumentPreview: builder.query({
            query: (documentId) => ({
                url: `/documents/${documentId}/preview`,
                method: 'GET',
                responseHandler: 'text'
            })
        })
    })
});
export const { useGetDocumentQuery, useLazyGetDocumentQuery, usePostDocumentMutation, usePutDocumentPagragraphMutation, useDeleteDocumentParagraphMutation, usePostDocumentParagraphMutation, useGetDocumentMetadataQuery, useGetDocumentVariablesQuery, useLazyGetDocumentVariablesQuery, usePostDocumentVariableMutation, useDeleteDocumentVariableMutation, usePutDocumentVariableMutation, useGetDocumentPrioritiesQuery, useLazyGetDocumentPrioritiesQuery, usePostDocumentPrioritiesMutation, useDeleteDocumentPrioritiesMutation, usePutDocumentPrioritiesMutation, useGetDocumentReusableParagraphsQuery, useGetDocumentTOCQuery, usePostDocumentMetadataMutation, useDeleteDocumentMetadataMutation, usePutDocumentMetadataMutation, useLazyGetDocumentPreviewQuery } = documentsApi;
