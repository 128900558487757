import { combineReducers } from '@reduxjs/toolkit';
import { documentsApi, DOCUMENTS_API_REDUCER_KEY } from './services/documents';
import { templatesApi, TEMPLATES_API_KEY } from './services/templates';
import { importableDocumentsApi, IMPORTABLE_DOCUMENTS_API_KEY } from './services/importableDocuments';
import { reusableParagaraphsApi, REUSABLE_PARAGRAPHS_API_KEY } from './services/reusableParagraphs';
import { tableModuleApi, TABLE_MODULE_API_KEY } from './services/tableModule';
import appReducer, { APP_REDUCER_KEY } from './slices/appSlice';
const rootReducer = combineReducers({
    [DOCUMENTS_API_REDUCER_KEY]: documentsApi.reducer,
    [TEMPLATES_API_KEY]: templatesApi.reducer,
    [IMPORTABLE_DOCUMENTS_API_KEY]: importableDocumentsApi.reducer,
    [APP_REDUCER_KEY]: appReducer,
    [REUSABLE_PARAGRAPHS_API_KEY]: reusableParagaraphsApi.reducer,
    [TABLE_MODULE_API_KEY]: tableModuleApi.reducer
});
export default rootReducer;
