export var PurchaseTypes;
(function (PurchaseTypes) {
    PurchaseTypes["digital_document_basis"] = "digital_document_basis";
    PurchaseTypes["digital_document_review"] = "digital_document_review";
    PurchaseTypes["digital_document_advice"] = "digital_document_advice";
    PurchaseTypes["upgrade_to_review"] = "upgrade_to_review";
    PurchaseTypes["upgrade_to_advice"] = "upgrade_to_advice";
    PurchaseTypes["registration"] = "registration";
    PurchaseTypes["court_fee"] = "court_fee";
})(PurchaseTypes || (PurchaseTypes = {}));
