import { init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
export function sentryInit(sentryUrl) {
    init({
        dsn: sentryUrl,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        ignoreErrors: [
            'Cannot read properties of undefined (reading \'consent\')',
            'window.CookieConsent is undefined',
            'undefined is not an object (evaluating \'window.CookieConsent.consent\')'
        ]
    });
}
